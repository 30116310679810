import * as React from "react"
import ContentLoader from "react-content-loader"
import * as styles from "./LoadingBillPanel.module.css"

const LoadingBillPanel = () => {
  return (
    <>
      <div className={styles.container}>
        <ContentLoader viewBox="0 0 350 120" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">

          <rect x="0" y="5" rx="4" ry="4" width="100%" height="35" />
          <rect x="10%" y="49" rx="4" ry="4" width="80%" height="15" />
        </ContentLoader>
      </div>
    </>
  )
}

export default LoadingBillPanel
