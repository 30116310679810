import * as React from "react"
import ContentLoader from "react-content-loader"
import * as css from "./LoadingCardDownloadContent.module.css"

const LoadingCardDownloadContent = () => {
  return (
    <>
      <div className={css.desktopView}>
        <ContentLoader viewBox="0 0 550 300" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">
          <rect x="0" y="2" rx="4" ry="4" width="20%" height="20" />
          <rect x="0" y="32" rx="4" ry="4" width="75" height="75" />
          <rect x="85" y="32" rx="4" ry="4" width="75" height="75" />
          <rect x="170" y="32" rx="4" ry="4" width="75" height="75" />

          <rect x="0" y="143" rx="4" ry="4" width="10%" height="15" />
          <rect x="0" y="172" rx="4" ry="4" width="32%" height="13" />
          <rect x="0" y="205" rx="4" ry="4" width="27%" height="13" />

          <rect x="0" y="237" rx="4" ry="4" width="49%" height="13" />
          <rect x="0" y="270" rx="4" ry="4" width="55%" height="13" />
        </ContentLoader>
      </div>
      <div className={css.mobileView}>
        <ContentLoader viewBox="0 0 1000 1050" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">
          <rect x="0" y="0" rx="10" ry="10" width="40%" height="80" />
          <rect x="0" y="120" rx="25" ry="25" width="280" height="280" />
          <rect x="310" y="120" rx="25" ry="25" width="280" height="280" />
          <rect x="620" y="120" rx="25" ry="25" width="280" height="280" />
          <rect x="0" y="450" rx="15" ry="15" width="80%" height="30" />

          <rect x="0" y="520" rx="10" ry="10" width="50%" height="80" />
          <rect x="0" y="640" rx="25" ry="25" width="800" height="280" />
          <rect x="0" y="970" rx="15" ry="15" width="30%" height="30" />
        </ContentLoader>
      </div>
    </>
  )
}

export default LoadingCardDownloadContent
